import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react"
import ReactDOM from "react-dom/client";
import Login from "./Login";
import Board from "./Board";
import UserContext from "../UserContext"

if (netlifyIdentity?.currentUser()) {
  console.log("Refreshed token??")
  netlifyIdentity?.refresh()
}

export default function App (props) {
  const [ user, setUser ] = useState(netlifyIdentity?.currentUser())

  useEffect(() => {
    console.log("Did effect?")
    netlifyIdentity.on("init", (user) => { setUser(user) });
    netlifyIdentity.on("login", (user) => {
      console.log("Called login?")
      setUser(user)
    });
    netlifyIdentity.on("logout", (user) => { setUser(user) });
  })

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Board />} />
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  )
}
