port module Main exposing (..)

import Board.Model
    exposing
        ( Flags
        , Model
        , Msg(..)
        , ReorderCard(..)
        , ReorderEvent
        )
import Board.Update exposing (init, update)
import Board.View exposing (view)
import Browser
import Browser.Dom as Dom
import Browser.Events
import Card as Card
import Custom.Events as Events
import Data.Board as Board
import Data.Card exposing (..)
import Debug
import Html.Events as Events
import Http
import Json.Decode as Decode exposing (Decoder)
import Json.Encode as Encode
import List exposing (..)
import List.Extra as List
import Maybe exposing (withDefault)
import String
import Board.Cmd
import Task
import Util exposing (..)


main : Program Flags Model Msg
main =
    Browser.element
        { init = init
        , update = update
        , view = view
        , subscriptions = subscriptions
        }


subscriptions : Model -> Sub Msg
subscriptions model =
    let
        decodeReorderEvent =
            Decode.map3 ReorderEvent
                (Decode.field "id" Decode.int
                    |> Decode.andThen (decodeFindCardById model)
                )
                (Decode.field "lane" Decode.string)
                (Decode.field "reorderId" (decodeReorder model))

        decodeDragEnterEvent =
            Decode.field "id" Decode.int
    in
    Sub.batch
        [ receiveOrderEvent (Reorder << Decode.decodeValue decodeReorderEvent)
        , receiveDragEnterEvent (DragEnterCard << Decode.decodeValue decodeDragEnterEvent)
        , Browser.Events.onKeyPress (Decode.map (handleKeyPress model) Events.keyCode)
        ]


handleKeyPress model code =
    if model.inputIsFocused then
        NoOp

    else
        case code of
            -- "x"
            120 ->
                CloseGroup

            _ ->
                NoOp


decodeFindCardById : Model -> Int -> Decoder Card
decodeFindCardById model id =
    case Card.findById id (Board.cards model.board) of
        Nothing ->
            Decode.fail "Couldn't find card by id while decoding."

        Just card ->
            Decode.succeed card


decodeReorder model =
    Decode.field "tag" Decode.string
        |> Decode.andThen (decodeReorderCard model)


decodeReorderCard model tag =
    case tag of
        "Top" ->
            Decode.succeed Top

        "Card" ->
            Decode.field "id" Decode.int
                |> Decode.andThen
                    (\id ->
                        case Card.findById id (Board.cards model.board) of
                            Nothing ->
                                Decode.fail "Couldn't find reorder target."

                            Just card ->
                                Decode.succeed (ReorderCard card)
                    )

        _ ->
            Decode.fail "Couldn't decode ReorderId."



-- type alias LaneOpts = {
--     showDescriptions : Boolean
-- }
-- , div [ attribute "class" "card-card-stack-hump" ] []
-- , div [ attribute "class" "card-card-stack-hump" ] []
-- ]
-- Make copies of cards when they come from recurring, because nifty.
-- Either this card or the cards under it if this is a heading.


port sendPursCmd : Encode.Value -> Cmd msg


port receivePursModel : (Decode.Value -> msg) -> Sub msg


port receiveOrderEvent : (Decode.Value -> msg) -> Sub msg


port receiveDragEnterEvent : (Decode.Value -> msg) -> Sub msg
