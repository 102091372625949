import { useState, useEffect, useContext } from "react"
import UserContext from "../UserContext"

function loggedInButtons (user, logout, manageSubscription) {
  if (!user) return null

  return (
    <>
      <button id="left" onClick={logout}>Log Out</button>
      <button id="left" onClick={manageSubscription}>Manage Subscription</button>
    </>
  )
}

function loggedOutButtons (user, login, signUp) {
  if (user) return null

  return (
    <>
      <button id="right" onClick={login}>Log In</button>
      <button id="right" onClick={signUp}>Sign Up</button>
    </>
  )
}

const manageSubscription = (user) => {
  fetch("/.netlify/functions/create-manage-link", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user.token.access_token}`,
    },
  })
  .then((res) => res.json())
  .then((link) => {
    window.location.href = link;
  })
  .catch((err) => console.error(err));
}

export default function Login (props) {
  const login = () => netlifyIdentity.open("login");
  const logout = () => netlifyIdentity.logout();
  const signUp = () => netlifyIdentity.open("signup");
  const { user, setUser } = useContext(UserContext)

  return (
    <div style={ { marginTop: "100px", padding: "50px" } }>
      <h1>Sign up for my fancy project board?!</h1>

      <div className="user-info">
        { loggedInButtons(user, logout, () => { manageSubscription(user) }) }
        { loggedOutButtons(user, login, signUp) }
      </div>
    </div>
  )
}
