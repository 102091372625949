import { Elm } from "../../../elm/src/Main.elm"
import React, { useEffect, useContext } from "react"
import UserContext from "../UserContext"
import { Navigate } from "react-router-dom";
import $ from "jquery"

// return $(function() {
//   var app;
//   app = Elm.Board.init({
//     node: document.getElementById("cards-attachment-point")
//   });
//   console.log("Ports? ", app.ports);

function initDragEffects (app) {
  app.ports.setupDragHandlers.subscribe(function(e) {
    var $nullableDraggedCard;
    $nullableDraggedCard = null;
    $(".card-lane").each(function() {
      var $lane;
      $lane = $(this);
      $lane.on("dragstart", function(e) {
        $nullableDraggedCard = $(e.target);
      });
      $lane.on("dragover", function(e) {
        e.preventDefault();
      });
      return $lane.on("dragenter", function(e) {
        var $dragEnteredCard, $draggedCard;
        // At this point, the dragged card should've been set on
        // dragstart.
        $draggedCard = $nullableDraggedCard;
        $dragEnteredCard = $(e.target);
        // { left: draggedCardX, top: draggedCardY } = $draggedCard.position()
        // $otherCards = ($lane.find ".card-card")
        // $nullableHoveredOverCard = null
        // $otherCards.each( ->
        // 	$elem = $ @
        // 	{ top: y, left: x } = $elem.position()
        // 	xIsOver =
        // 		x > draggedCardX > x + $elem.width() or
        // 		draggedCardX > x > draggedCardX + $draggedCard.height()
        // 	yIsOver =
        // 		y > draggedCardY > y + $elem.height() or
        // 		draggedCardY > y > draggedCardY + $draggedCard.height()

        // 	if xIsOver and yIsOver
        // 		$nullableHoveredOverCard = $elem
        // ).get()

        // console.log "2723 pos: ", $("#card-2723").position()
        // console.log "Dragged pos: ", $draggedCard.position()
        // console.log "Entered: ", $dragEnteredCard.get(0)
        // console.log "Dragged card: ", $draggedCard.get(0)
        // console.log "Sending id: ", parseInt $dragEnteredCard.attr("data-id")
        // Needed for drop
        e.preventDefault();
        return app.ports.receiveDragEnterEvent.send({
          id: parseInt($dragEnteredCard.attr("data-id"))
        });
      });
    });
  });

  app.ports.sendDropEvent.subscribe(function({id, lane, clientY}) {
    var $card, $cards, afterIdOrMinusOne, beforeIdxOrMinusOne, cards, dragCard, dropY, dropZone, ref, ref1;
    $card = $(`#card-${id}`);
    dragCard = $card.get(0);
    dropZone = ($(`.card-lane[data-lane=${lane}`)).get(0);
    dropY = clientY;
    $cards = ($(`[data-lane=${lane}`)).find(".js-card").map(function(idx, child) {
      var y;
      ({y} = child.getBoundingClientRect());
      return {
        y,
        id: parseInt(($(child)).attr("data-id"))
      };
    });
    cards = $cards.get().filter(function(c) {
      return c.id !== id;
    });
    beforeIdxOrMinusOne = cards.sort(function({
        y: y1
      }, {
        y: y2
      }) {
      return y1 - y2;
    }).findIndex(function({
        y: cY
      }) {
      return cY > dropY;
    });
    if (beforeIdxOrMinusOne === -1) {
      // We couldn't find the element that we want to insert our card
      // *before*. That means that either the list is empty or we're
      // sticking it at the end.
      afterIdOrMinusOne = (ref = (ref1 = cards[cards.length - 1]) != null ? ref1.id : void 0) != null ? ref : -1;
    } else if (cards[beforeIdxOrMinusOne - 1] == null) {
      // We're trying to insert at the beginning since we said that we
      // want to insert *before* the first element.
      afterIdOrMinusOne = -1;
    } else {
      // We're sticking it after the given id.
      afterIdOrMinusOne = cards[beforeIdxOrMinusOne - 1].id;
    }
    app.ports.receiveOrderEvent.send({
      id,
      lane,
      reorderId: afterIdOrMinusOne === -1 ? {
        tag: "Top"
      } : {
        tag: "Card",
        id: afterIdOrMinusOne
      }
    });
    return true;
  });
}

export default function Board (props) {
  const { user, setUser } = useContext(UserContext)
  const nullableAccessToken = user?.token?.access_token || null

  useEffect(() => {
    $("body").css({ "background-color": "black" })
    const mountEl = document.getElementById("mnt")
    let authToken

    if (authToken = nullableAccessToken) {
      const app = Elm.Main.init({ node: mountEl, flags: { authToken } })
      initDragEffects(app)
    }
    // else users will be redirected to the home page in render
  })

  return (
    !nullableAccessToken
      ? <Navigate to="/login" />
      : <div id="mnt"></div>
  )
}
